<template>
  <v-container class="mt-10">
    <v-row justify="center">
      <v-col md="6">
        <template>
          <v-data-table :dark="$store.state.isDarkMode" :headers="headers" :items="names" item-key="id"
            :loading="loading" hide-default-footer style="border: 1px solid #ccc; border-radius: 4px">
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.name }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.description }}</td>
                <td>
                  <v-icon color="red" @click="deleteItem(item)">mdi-delete</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template></v-col>
      <v-col md="3">
        <v-card flat outlined class="pa-5">
          <v-text-field dense outlined :label="$t('name')" v-model="name"></v-text-field>
          <v-text-field dense outlined :label="$t('description')" v-model="description"></v-text-field>
           <v-text-field :dark="$store.state.isDarkMode" type="date" dense outlined color="#757575" :label="$t('date')"
              v-model="date"></v-text-field>
          <v-btn small color="success" class="my-3" @click="submit">{{ $t("create") }} <v-icon>mdi-plus</v-icon></v-btn>
        </v-card>
      </v-col>
    </v-row></v-container>
</template>

<script>
import axios from "axios";
export default {
  created() {
    this.initiate();
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Date", value: "date" },
        { text: "Description", value: "description" },
      ],
      names: [],
      name: "",
      description: "",
      date: "",
      loading: true,
    };
  },
  methods: {
    async initiate() {
      await axios.get("/hr/holiday/offcial").then((res) => {
        this.names = res.data.offcial_holidays;
        this.loading = false;
      });
    },
    submit() {
      axios
        .post("/hr/holiday/offcial", {
          name: this.name,
          description: this.description,
          date: this.date,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$Notifications(
              "تم إاضافةعطلة رسمية جديدة بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
            (this.name = ""), (this.description = ""), (this.date = "");
          }
          this.initiate();
        });
    },
    deleteItem(item) {
      axios.delete(`/hr/holiday/offcial/${item.id}`).then((res) => {
        if (res.status == 200) {
          this.$Notifications(
            "تم حذف عطلة رسمية جديدة بنجاح",
            { rtl: true, timeout: 2500 },
            "success"
          );
          this.initiate()((this.name = "")),
            (this.description = ""),
            (this.date = "");
        }
      });
    },
  },
};
</script>
